.sidebar {
    padding-left: 30px;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    z-index: 100;
    h1 {
        pointer-events: initial;
        max-width: 300px;
    }
    h3 {
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
        margin-bottom: 2px;
        pointer-events: none;
    }
    .sidebar-header {
        position: relative;
        width: 310px;
        h1 {
            margin: 0px;
            padding: 0px;
        }
        button.ui.button {
            position: absolute;
            top: 0px;
            right: 0px;
            padding-left: 20px;
            padding-right: 20px;
            background: rgba(220,220,220, 0.5);
            pointer-events: initial;
            border: 0px solid transparent;
            box-shadow: none;
        }
    }
    .pattern-id {
        margin: 0px;
        padding: 0px;
        padding-bottom: 5px;
    }
    .tab-selection {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 295px;
        button.ui.button {
            pointer-events: initial;
        }
    }
    .show-hide-tab.button {
        position: absolute;
        pointer-events: auto;
        top: 20%;
        left: 2px;
        padding: 6px;
        padding-top: 100px;
        padding-bottom: 100px;
        opacity: 0.3;
        z-index: 100;
    }
    .slide-panel {
        position: relative;
        left: 0px;
        width: calc(100vw - 20px);
        overflow: hidden;
        height: calc(100vh - 41px);
        transition: left 0.5s;
        pointer-events: none;
        .model-settings-container {
            position: absolute;
            top: 80px;
            z-index: 1000;
            transition: left 0.4s;
            pointer-events: auto;
            .ui.card {
                padding: 10px;
                width: 350px;
            }
            .ui.input.italic > input {
                font-style: italic;
            }
        }
        &.hidden {
            left: -420px;
        }
        .scroll-container {
            width: calc(100vw - 0px);
            max-height: calc( 100vh - 41px );
            padding-top: 20px;
            padding-bottom: 0px;
            overflow-y: scroll;
            pointer-events: none;
            & > div {
                width: 330px !important;
                pointer-events: auto;
                max-width: 330px !important;
            }
            .elements-simulation-list {
                pointer-events: initial;
            }
        }
    }
    .segment {
        margin-top: 10px;
    }
    
}