.construction-view {
    .construction-settings {
        position: absolute;
        z-index: 1000;
        top: 20px;
        left: 335px;
        pointer-events: initial;
        .ui.card {
            pointer-events: initial;
            button.ui.button {
                width: 100%;
            }
        }
    }
}