.simulation-view {
    position: relative;
    .simulation-settings {
        position: absolute;
        top: 20px;
        left: 335px;
        button.ui.button.close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            width: initial;
        }
    }
    
}
.simulation-view-sidebar-panel {
    max-width: 295px;
}