.model-settings {
    position: absolute;
    left: 350px;
    top: 20px;
    z-index: 100;
    button.ui.button {
        width: 100%;
    }
    button.ui.button.close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: initial;
    }
    
    h2 {
        margin-top: 0px;
    }
}