.elements-list {
    .draggable-list {
        pointer-events: none;
        .draggable-list-item {
            position: relative;
            width: 293px;
            max-width: 293px;
            .ui.list {
                pointer-events: none;
            }
            .select-div {
                position: absolute;
                left: 0px;
                right: 0px;
                top: 0px;
                bottom: 0px;
                width: 100%;
                height: 100%;
                pointer-events: initial;
                z-index: 1;
                cursor: grab;
                &:hover {
                    background: rgba(200,200,200, 0.1);
                }
            }
            .ui.button {
                z-index: 10;
                pointer-events: initial;
            }
            .list-content {
                position: relative;
                pointer-events: none;
                .left-content {
                    display: flex;
                    pointer-events: none;
                    padding-left: 3px;
                    p {
                        margin: 0px;
                        padding-left: 5px;
                        max-width: 250px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        pointer-events: none;
                        .label {
                            opacity: 0.5;
                            font-style: italic;
                        }
                    }
                    .ui.button.left {
                        margin: 0px;
                        padding: 4px;
                        margin-right: 3px;
                    }
                }
                .right-content {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    z-index: 10;
                    .ui.button {
                        margin: 0px;
                        padding: 4px;
                        margin-right: 3px;
                        pointer-events: initial;
                        z-index: 10;
                    }
                }
            }
            &.selected {
                .list-content {
                    color: #15232E;
                    p {
                        background: rgba(100,100,100,0.1);
                        padding-right: 4px;
                        border-radius: 3px;
                    }
                    font-weight: 700;
                }
            }
            .edit-element-pop-up {
                position: absolute;
                left: calc( 100% + 5px );
                top: -15px;
                .ui.input.changed {
                    input {
                        font-style: italic !important;
                    }
                }
                .close-button {
                    float: right;
                    background: transparent;
                    border: none;
                    color: black;
                    margin: 0px;
                    padding: 0px;
                    position: relative;
                    right: -6px;
                    .icon {
                        font-size: 1.5em;
                        margin: 0px;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

        }
    }
}