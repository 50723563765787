h1, h2, h3, h4, h5, p, li, ul, strong, i, a, button, input, label {
  font-family: Europa;
}

button.ui.button {
  font-family: Europa;
}
.ui.input {
  input {
    font-family: Europa;
  }
}

.app {
  .page-content {
  }
}

.ui.button.toggle.active, .ui.buttons .button.toggle.active, .ui.toggle.buttons .active.button {
  background-color: #15232E !important;
}

.ui.button.toggle.active:hover {
  background-color: #A882BA !important;
}

.ui.input.italic > input {
  font-style: italic;
}