.elements-list {
    h4 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 0.9em;
    }
    .new-element-pop-up {
        width: 310px;
        padding-top: 4px;
        .ui.input {
            > input {
                width: 181px !important;
                pointer-events: initial;
            }
        }
        button.ui.button {
            margin-left: 5px;
            width: 110px;
        }
    }

    .geometry.editting {
    }
    .ui.message {
        width: 297px;
        padding: 3px;
        padding-left: 6px;
    }
    button.ui.button {
        pointer-events: initial;
        &.preview {
            width: 297px;
        }
    }
    .add-procedure-form {
        margin-top: 10px;
        h4 {
            margin-bottom: 5px;
        }
        .add-procedure-module {
            pointer-events: initial;
            .ui.form {
                .field {
                    margin-bottom: 5px;
                }
            }
            input {
                pointer-events: initial;
            }
            button {
                width: 100%;
            }
        }
    }
    
}