.procedures-list {
    [contenteditable] {
        outline: 0px solid transparent;
    }
    .ui.selection {
        pointer-events: initial;
        width: 100%;
        margin-bottom: 5px;
    }
    .checkbox {
        pointer-events: initial;
        margin-right: 15px;
        label {
            padding-left: 1.4em;
        }
    }
    .procedure {
        margin-bottom: 10px;
        padding: 5px;
        background: rgba(220,220,220, 0.5);
        border-radius: 5px;
        position: relative;
        width: 305px;
        .ui.input {
            pointer-events: initial;
            user-select: initial;
            &.procedure-name {
                input {
                    padding: 2px;
                    padding-left: 5px;
                    background: transparent;
                    border: 1px solid transparent;
                    font-weight: 700 !important; 
                    font-family: europa;
                }
                &.editted {
                    input {
                        border: 1px solid white;
                        font-style: italic !important;
                    }
                    
                }
            }
            
            
            .ui.buttons {
                padding: 0px;
                button.ui.button {
                    padding: 5px !important;
                    font-size: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    background: black;
                    width: 50px;
                    color: white;
                }
            }
        }
        button.ui.button.delete-button {
            position: absolute;
            top: 0px;
            right: -3px;
            background: transparent;
        }
        .ui.buttons {
            width: 100%;
        }

        .show-editor-button-toggle {
            width: 260px;
            padding: 3px;
        }
        .type-label {
            text-align: right;
            position: absolute;
            top: 8px;
            right: 25px;
            opacity: 0.5;
            font-weight: 600;
            font-size: 11px;
            text-transform: uppercase;
            pointer-events: none;
            user-select: none;
            z-index: -1;
        }
        .settings {
            padding-top: 10px;
        }
    }

    .add-procedure-form {
        .add-procedure-module {
            margin-left: 20px;
            width: 285px;
        }
    }

    .procedure-color-picker {
        > div {
            pointer-events: initial;
            display: block !important;
            > div {
                box-shadow: none !important;
            }
        }
    }
}