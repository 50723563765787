.position-editor {
    background: rgba(220,220,220, 0.5);
    border-radius: 5px;
    padding: 5px;
    max-width: 295px !important;
    margin-bottom: 20px;
    .ui.input {
        input {
            padding: 3px;
            line-height: 1 !important;
        }
        .ui.basic.label {
            padding: 3px;
            padding-left: 10px;
            padding-right: 10px;
            color: grey;
            font-weight: 100;
        }
    }
    .ui.buttons {
        width: 100%;
    }
    
}