.elements-list {
    .inputs {
        .ui.list {
            margin-bottom: 3px;
            .item {
                position: relative;
                max-width: 300px;
                width: 305px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                border: 1px solid rgba(100,100,100,0.05);
                margin-top: 0px;
                border-radius: 6px;
                background: rgba(255,255,255,0.5);
                p {
                    margin: 0px;
                    padding: 0px;
                    padding-top: 3px;
                    padding-bottom: 5px;
                    padding-left: 6px;
                }
                &:hover {
                    background: rgba(240,240,240,0.5);
                }
                button.ui.button {
                    position: absolute;
                    top: 2px;
                    right: 3px;
                    padding: 7px;
                    padding-top: 5px;
                    pointer-events: initial;
                    
                }
            }
        }
        
        
    }
}